<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        For each of the molecules below, determine the oxidation state for every atom in the
        compound. Note that none of the oxygen atoms are in the peroxide or superoxide form.
      </p>

      <p class="mb-0 pb-0">
        <chemical-latex :content="compoundA" />
      </p>
      <v-row class="pl-7 mb-2">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS1A"
            class="pb-3"
            :prepend-text="atom1A"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-0 pb-0">
        <chemical-latex :content="compoundB" />
      </p>
      <v-row class="pl-7 mb-2">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS1B"
            class="pb-3"
            :prepend-text="atom1B"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS2B"
            class="pb-3"
            :prepend-text="atom2B"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-0 pb-0">
        <chemical-latex :content="compoundC" />
      </p>
      <v-row class="pl-7 mb-2">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS1C"
            class="pb-3"
            :prepend-text="atom1C"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS2C"
            class="pb-3"
            :prepend-text="atom2C"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS3C"
            class="pb-3"
            :prepend-text="atom3C"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-0 pb-0">
        <chemical-latex :content="compoundD" />
      </p>
      <v-row class="pl-7">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS1D"
            class="pb-3"
            :prepend-text="atom1D"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS2D"
            class="pb-3"
            :prepend-text="atom2D"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS3D"
            class="pb-3"
            :prepend-text="atom3D"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question262',
  components: {CalculationInput, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        OS1A: null,
        OS1B: null,
        OS2B: null,
        OS1C: null,
        OS2C: null,
        OS3C: null,
        OS1D: null,
        OS2D: null,
        OS3D: null,
      },
    };
  },
  computed: {
    versionNumberA() {
      return this.taskState.getValueBySymbol('versionNumberA').content;
    },
    versionAData() {
      switch (this.versionNumberA.value) {
        case 1:
          return {
            compoundA: 'a) O3',
            atom1A: 'O:',
          };
        case 2:
          return {
            compoundA: 'a) S8',
            atom1A: 'S:',
          };
        case 3:
          return {
            compoundA: 'a) P4',
            atom1A: 'P:',
          };
        case 4:
          return {
            compoundA: 'a) N4',
            atom1A: 'N:',
          };
        default:
          return {
            compoundA: null,
            atom1A: null,
          };
      }
    },
    compoundA() {
      return this.versionAData.compoundA;
    },
    atom1A() {
      return this.versionAData.atom1A;
    },
    versionNumberB() {
      return this.taskState.getValueBySymbol('versionNumberB').content;
    },
    versionBData() {
      switch (this.versionNumberB.value) {
        case 1:
          return {
            compoundB: 'b) Ag2S',
            atom1B: 'Ag:',
            atom2B: 'S:',
          };
        case 2:
          return {
            compoundB: 'b) Li2O',
            atom1B: 'Li:',
            atom2B: 'O:',
          };
        case 3:
          return {
            compoundB: 'b) CaCl2',
            atom1B: 'Ca:',
            atom2B: 'Cl:',
          };
        case 4:
          return {
            compoundB: 'b) Al2O3',
            atom1B: 'Al:',
            atom2B: 'O:',
          };
        default:
          return {
            compoundB: null,
            atom1B: null,
            atom2B: null,
          };
      }
    },
    compoundB() {
      return this.versionBData.compoundB;
    },
    atom1B() {
      return this.versionBData.atom1B;
    },
    atom2B() {
      return this.versionBData.atom2B;
    },
    versionNumberC() {
      return this.taskState.getValueBySymbol('versionNumberC').content;
    },
    versionCData() {
      switch (this.versionNumberC.value) {
        case 1:
          return {
            compoundC: 'c) H2CO',
            atom1C: 'H:',
            atom2C: 'C:',
            atom3C: 'O:',
          };
        case 2:
          return {
            compoundC: 'c) CH4O',
            atom1C: 'C:',
            atom2C: 'H:',
            atom3C: 'O:',
          };
        case 3:
          return {
            compoundC: 'c) K2CrO4',
            atom1C: 'K:',
            atom2C: 'Cr:',
            atom3C: 'O:',
          };
        case 4:
          return {
            compoundC: 'c) H2SO4',
            atom1C: 'H:',
            atom2C: 'S:',
            atom3C: 'O:',
          };
        default:
          return {
            compoundC: null,
            atom1C: null,
            atom2C: null,
            atom3C: null,
          };
      }
    },
    compoundC() {
      return this.versionCData.compoundC;
    },
    atom1C() {
      return this.versionCData.atom1C;
    },
    atom2C() {
      return this.versionCData.atom2C;
    },
    atom3C() {
      return this.versionCData.atom3C;
    },
    versionNumberD() {
      return this.taskState.getValueBySymbol('versionNumberD').content;
    },
    versionDData() {
      switch (this.versionNumberD.value) {
        case 1:
          return {
            compoundD: 'd) Na2Cr2O7',
            atom1D: 'Na:',
            atom2D: 'Cr:',
            atom3D: 'O:',
          };
        case 2:
          return {
            compoundD: 'd) CaCr2O7',
            atom1D: 'Ca:',
            atom2D: 'Cr:',
            atom3D: 'O:',
          };
        case 3:
          return {
            compoundD: 'd) HClO3',
            atom1D: 'H:',
            atom2D: 'Cl:',
            atom3D: 'O:',
          };
        case 4:
          return {
            compoundD: 'd) HClO4',
            atom1D: 'H:',
            atom2D: 'Cl:',
            atom3D: 'O:',
          };
        default:
          return {
            compoundD: null,
            atom1D: null,
            atom2D: null,
            atom3D: null,
          };
      }
    },
    compoundD() {
      return this.versionDData.compoundD;
    },
    atom1D() {
      return this.versionDData.atom1D;
    },
    atom2D() {
      return this.versionDData.atom2D;
    },
    atom3D() {
      return this.versionDData.atom3D;
    },
  },
};
</script>
